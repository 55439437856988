.upgrade-plan-container {
  max-width: 80%;
  margin: auto;
  text-align: center;
  background-color: #282c34;
}

.card-title {
  font-size: 1.5rem;
  color: #fff;
}
#main-plus-payment-card {
  margin-bottom: 1rem;
}
#main-team-payment-card {
  margin-top: 0;
}

@media screen and (max-width: 768px) {
  #main-team-payment-card {
    margin-top: 1rem;
  }
  #main-plus-payment-card {
    margin-bottom: 1rem;
  }
}

.card-subtitle {
  font-size: 1.2rem;
  color: #555;
}

.upgrade-card {
  border: 1px solid #bbff4b !important;
  height: auto;
  min-height: 74vh;
  background-color: rgb(7, 7, 7) !important;
  display: flex;
  color: #ddd !important;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 768px) {
  .upgrade-card {
    height: 100vh !important;
  }
}

.upgrade-button {
  background-color: #bbff4b !important;
  border: none !important;
  color: #333 !important;
  font-size: 1.2rem;

  box-shadow: 0 4px 8px #bbff4b !important;
}

.upgrade-button:hover {
  background-color: #aaf532 !important;
}

ul {
  text-align: left;
  padding-left: 0;
}

ul li {
  font-size: 1rem;
  margin-bottom: 10px;
}

.text-center a {
  color: #007bff;
  text-decoration: none;
}

.text-center a:hover {
  text-decoration: underline;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 65%;
  position: relative;
  top: 2%;
  color: #ddd !important;
}
.card:hover {
  border: 2px solid white!important;
  /* transform: scale(1.01)!important; */
}

@media screen and (max-width: 768px) {
  .card-body {
    top: 0 !important;
  }
}

h5 {
  color: #bbff4b !important;
}
h6 {
  color: #bbff4b !important;
  font-family: Cont-regular !important;
}

.line {
  background-color: #ffffff;
  width: 10px;
  height: 39px;
  position: relative;
  transform: rotate(45deg);
  animation: lines 1s ease-in  infinite 2s alternate;
}

.line:hover {
  background-color: #000000;
  /* : all ease-in; */
}

@keyframes lines {
  from {
    left: 0px;
  }
  to {
    left: 100px;
  }
}

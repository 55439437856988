:root {
  --primary-bg-color: #282c34;
  --secondary-bg-color: #575969;
  font-family: "Shriftovik" !important;
  --bs-body-font-family: "Shriftovik" !important;
}

.App {
  /* text-align: center; */
  background-color: var(--primary-bg-color);
  color: #fff;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-family: "Shriftovik";
}
h1 {
  /* font-family: "Cont-regular"; */
  font-size: 20px;
}
text {
  /* font-family: "Cont-regular"; */
  font-size: 16px;
  color: #000;
}
li {
  /* font-family: "Cont-regular"; */
  font-size: 16px;
}
button {
  /* font-family: "Cont-regular" !important; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

p {
  /* font-family: "Cont-regular"; */
  color: white;
  font-size: 16px;
}
Button {
  /* font-family: "Cont-regular"; */
}
textarea {
  /* font-family: "Cont-regular"; */
}
input {
  /* font-family: "Cont-regular"; */
}
.input-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* bottom: 5%;
  position: absolute; */
}
@media (max-width: 768px) {
  /* .input-main-container {
    width: 99%;
    height: 44vh;
  } */
}
@media (min-width: 768px) {
  .input-main-container {
    width: 100%;
    /* height: 35%; */
  }
}

@font-face {
  font-family: "regular";
  font-style: normal;
  font-weight: normal;
  src: url("/public/Fonts/BEXAGO-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Heading";
  font-weight: normal;
  src: url("/public/Fonts/BEXAGO-Outline.ttf") format("truetype");
}
@font-face {
  font-family: "Cont-regular";
  font-weight: normal;
  src: url("/public/Fonts/Shriftovik-SK-Concretica-Regular.ttf")
    format("truetype");
}

.sideMenuButton {
  /* font-family: "Cont-regular"; */
  font-size: 16px;
}

header {
  display: none;
  position: absolute;
  width: 100%;
  z-index: 3;
}
header h1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #d9d9e3;
  margin: auto;
}
.menu button {
  background: none;
  border: none;
}
nav {
  display: none;
  background-color: #4c4f5f;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  height: 100vh;
}
.navItems {
  cursor: pointer;
  position: relative;
  transition: filter 0.3s;
  display: flex;
  height: 92%;
  flex-direction: column;
  width: 100%;
  /* max-width: 340px; */
}
.navPromptWrapper {
  border-bottom: 1px solid #ffffff33;
  padding: 0 15px;
  height: 100%;
  overflow-x: hidden !important;
  overflow-y: scroll;
  margin-top: 5px;
}
@media (min-width: 1601px) {
  .navPromptWrapper {
    /* height: calc(107vh - 330px); */
  }
}
.navContainer .navPrompt {
  justify-content: left;
}
.navPrompt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  column-gap: 20px;
  margin: 5px 0;
  text-align: left;
  /* border-radius: 15px; */
  padding: 5px 15px;
}
.navPrompt a {
  display: flex;
  align-items: center;
  column-gap: 20px;
  text-decoration: none;
}
.navPrompt p {
  font-size: 14px;
  line-height: 20px;
  color: #bbff4b;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 170px;
  margin: 0;
}

.navPrompt span {
  animation: fadeInChar 1s ease-in forwards;
  opacity: 0;
}
#botMessage pre {
  margin: 0;
  padding: 0;
  text-indent: 0;
  white-space: pre-wrap;
  position: relative;
  top: -45px;
}
pre {
  font-family: var(--text-font);
}
@keyframes fadeInChar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.navCloseIcon {
  width: 10%;
}
nav svg {
  float: left;
}
.sideMenu {
  width: 20rem;
  /* padding: 8px; */
  background-color: #323232;
}
.sideMenuButton {
  border: 1px solid hsl(0deg 0% 100% / 20%);
  border-radius: 5px;
  /* padding: 10px; */
  text-align: center;
  font-size: 14px;
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
}
.navPrompt:hover {
  cursor: pointer;
  background-color: #bbff4b33;
}
.sideMenuButton:hover{
}
.sideMenuButton span {
  font-size: 20px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 10px;
}
.side-logo {
  height: 30px;
  border-radius: 999px;
  overflow: hidden;
}

.side-logo-history {
  height: 50px;
  /* width: 90%; */
}

.chatBox {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #353540;
  position: relative;
  line-height: 24px;
  background-color: #000;
  font-size: 16px;
}
#introsection::before,
#introsection::after {
  /* content: "<HomePage/>"; */
  float: left;
  color: #bbff4b;
}
#introsection {
  text-align: center;
  padding: 20px;
}
/* #introsection h1 {
  padding-top: 20px;
  line-height: 33px;
  color: white
} */
.gradient-text {
  background: linear-gradient(45deg, #bbff4b, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
#introsection h2 {
  font-size: 16px;
  font-weight: 400;
}
#introsection ul {
  list-style-type: square;
}
#introsection pre {
  margin: 0;
  display: inline;
}
.chatPromptMainContainer {
  width: 100%;
  display: flex;
  justify-content: end;
}
.chatLogWrapper {
  height: 75%;
  max-height: 90%;
  width: 100%;
  position: absolute;
  overflow-y: auto;
  padding: 20px 15%;
  top: 30px;
}
.chatLogWrapperHistory {
  height: 80vh;
  width: 100%;
  position: absolute;
  overflow-y: auto;
}
.chatLog {
  height: auto;
}
.introsection-header-main {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
@media (max-width: 768px) {
  .introsection-header {
    display: none !important;
  }
}

.introsection-header {
  font-size: 25px;
  font-weight: 500;
  color: #d1d5db;
  height: 100px;
  width: 150px;
  left: 0%;
  top: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  /* border: 1px solid #000; */
  margin: 0;
  padding-left: 1rem;
  font-weight: 700;
  position: absolute;
}

/* adding custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  background-color: #575969;
}
::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

::-moz-scrollbar {
  width: 8px;
  background-color: #575969;
}
::-moz-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

/* .chatPromptWrapper {
  margin: auto;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
} */

.userSVG {
  /* transform: scale(0.45); */
  height: 35px;
  width: 35px;
  /* background-color: yellow; */
  border-radius: 50%;
  display: inline-block;
}
.botMessageMainContainer {
  width: 100%;
  position: relative;
}
.botMessageWrapper {
  /* max-width: 800px; */
  margin: auto;
  padding: 24px 0;
  width: 100%;
  display: inline-block;
  background-color: #000;

  /* overflow-y: scroll; */
  /* text-align: left; */
  /* display: flex; */
  /* justify-content: left; */
  /* align-items: center; */
  /* column-gap: 25px; */
  position: relative;
}
.stop-messgage {
  position: absolute;
  bottom: 10px;
  right: 100px;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 10%;
  border: 1px solid rgb(86, 88, 105);
  background-color: rgb(52, 53, 65);
  color: rgb(217, 217, 217);
  cursor: pointer;
}
.stop-messgage:hover {
  background-color: rgb(64, 65, 79);
  /* border: none; */
}
.errorMessage {
  color: #ef4444;
  font-size: 16px;
  line-height: 24px;
}

.openaiSVG {
  display: inline-block;
  height: 80px;
}
#avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: contain;
  object-position: center;
}
.inputContainer {
  position: relative;
  width: 100%;
  max-width: 80%;
}

.inputPromptWrapper {
  display: flex;
  position: fixed;
  width: 90%;
  top: 58vh;
  /* left: auto; */
  justify-content: center;
  align-items: flex-end;
}
.submit-button-textarea {
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: auto;
  /* align-self: end; */
}

.inputPrompttTextarea {
  padding: 1rem;
  flex: 1 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  resize: none;
  outline: none;
  background-color: #000;
  box-sizing: border-box;
  color: #fff;
  border: 1px solid #bbff4b;
  font-size: 16px;
  border-radius: 20px;
  max-width: 60%;
  position: relative;
  background-color: #323232;
}

textarea {
  width: 100%;
  max-height: 200px;
  min-height: 40px;
  resize: none;
}

/* Hide the default scrollbar */
.input::-webkit-scrollbar {
  display: none;
}

.input::-webkit-scrollbar-track {
  display: none;
}

.input::-webkit-scrollbar-thumb {
  display: none;
}

.input {
  background: transparent;
  color: #fff;
  font-size: 16px;
  /* padding: 10px 20px; */
  border: transparent;
  position: relative;
  overflow-y: hidden;
  height: 100%;
  width: 100%;

  background-color: transparent; /* bg-transparent */
  color: #ffffff; /* text-[#ffffff] */
  border: none; /* border-0 */
  outline: none; /* focus:ring-0 and focus:border-0 */
  resize: none; /* resize-none */
  border-radius: 1.5rem; /* rounded-3xl */
  box-shadow: inset 0 0 5px rgba(187, 255, 75, 0.5); /* Custom inline style */
  overflow-y: auto;
  padding: 7px;
}
.input:focus {
  border: none;
  outline: none;
}
form button {
  background: #bbff4b;
  border-radius: 5px;
  border: none;
  height: 45px;
  width: 50%;
}

.loginContainer {
  background: var(--primary-bg-color);
  width: 100%;
  height: 100%;
  font-family: var(--text-font);
}

.loginContainerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.loginContainerContent svg {
  padding-bottom: 30px;
}

.loginContainer h1 {
  font-size: 30px;
  margin: 0;
}

.loginContainer p {
  font-size: 18px;
  line-height: 5px;
}

.loginButtonWrapper {
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin-top: 20px;
}

#loginButton {
  padding: 10px 25px;
  border-radius: 7px;
  color: #333;
  background: #bbff4b;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
#loginButton:hover {
  background-color: rgb(177, 255, 52);
}
@media screen and (max-width: 400px) {
  #introsection {
    margin-top: -25px !important;
  }
}

@media screen and (max-width: 768px) and (min-width: 400px) {
  #introsection {
    text-align: center;
    margin-top: 100px !important;
  }
  .mainSettingBody {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    height: 99vh;
    background-color: #fff;
    width: 87%;
    overflow-x: hidden;
  }
  .settingHeading {
    height: 27%;
    width: 97%;
    color: black;
    border-bottom: 1px solid rgb(235, 235, 235);
    background-color: #fff;
    /* border: 1px solid green; */
  }
  .settingButton {
    background-color: rgb(219, 216, 216);
    color: grey;
    outline: none;
    position: absolute;
    left: 12rem;
    border: none;
    border-radius: 20px;
    height: 35px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input-search-main {
    width: 97%;
    height: 40%;
    position: relative;
    top: 2rem;
    display: flex;
    justify-content: center;
  }
  .input-search-setting {
    width: 93%;
    height: 40px;
    display: flex;
    border: 1px solid grey;
    border-radius: 10px;
    padding-left: 1rem;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 400px) {
  .inputPrompttTextarea {
    min-height: 46vh;
    max-height: 46vh;
    margin-top: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .inputPrompttTextarea {
    height: fit-content;
  }
}

@media screen and (max-width: 1024px) {
  .App {
    display: block;
    width: 100%;
  }
  header {
    display: flex;
    align-items: center;
    background: #353441;
    border-bottom: 1px solid hsl(0deg 0% 100% / 20%);
    padding: 4px 0 0 12px;
  }
  nav {
    display: flex;
    width: 25%;
  }
  .chatLogWrapper {
    padding: 40px 10%;
  }
  .sideMenu {
    display: none;
  }
  .chatBox {
    position: static;
    height: 100%;
    width: 100%;
  }
  /* .chatPromptWrapper {
    flex-direction: row;
    width: 25%;
    /* display: flex;
    justify-content: end; 
    padding: 12px;
  } */
  .botMessageWrapper {
    padding: 12px;
  }
  .stop-messgage {
    right: 5px;
    font-size: 13px;
    padding: 8px 15px;
  }
  .userSVG {
    display: inline-block;
  }
  .openaiSVG {
    display: inline-block;
  }
  #avatar {
    width: 30px;
    height: 30px;
  }
  #introsection {
    padding: 40px 20px 40px 20px;
    font-size: 14px;
  }
  #introsection h1,
  pre {
    font-size: 16px;
    white-space: pre-wrap;
  }
  #introsection h2 {
    font-size: 14px;
  }
}

@media screen and (min-width: 1536px) {
  .loginContainer h1 {
    font-size: 40px;
  }
  .loginContainer p {
    font-size: 25px;
  }
  #loginButton {
    font-size: 22px;
  }
}

.mainSettingBody {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  height: 99vh;
  width: 87%;
  background-color: #fff;
  overflow-x: hidden;
}
.settingHeading {
  height: 31%;
  width: 97%;
  color: black;
  border-bottom: 1px solid rgb(235, 235, 235);
  background-color: #fff;
  /* border: 1px solid green; */
}
.settingButton {
  background-color: rgb(219, 216, 216);
  color: grey;
  outline: none;
  position: absolute;
  left: 12rem;
  border: none;
  border-radius: 20px;
  height: 35px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-search-main {
  width: 97%;
  height: 40%;
  position: relative;
  top: 2rem;
  display: flex;
  justify-content: center;
}
.input-search-setting {
  width: 93%;
  height: 40px;
  display: flex;
  border: 1px solid grey;
  border-radius: 10px;
  padding-left: 1rem;
  justify-content: center;
  align-items: center;
}
.input-group {
  width: 99% !important;
}

.table.table-hover {
  width: 99% !important;
}
.search-results-dropdown {
  position: absolute;
  top: 40px;
  left: 0;
  width: 94%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background: #fff;
  list-style: none;
  padding: 0;
  margin-left: 3rem;
  z-index: 1000;
  top: 1.7rem;
}

.search-results-dropdown li {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.search-results-dropdown li:hover {
  background: #f0f0f0;
}

.selected-user-details {
  margin-top: 10px;
  font-size: 1.2rem;
}

/* navigation */

.navContainer {
  /* height: 15%; */
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.userSection {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding: 0 10px;
  font-size: 14px;
  gap: 5px;
}
.userSection:hover {
  cursor: pointer;
  background: #c9ff4d33;
  border-radius: 5px;
}
.userSection.active {
  background: #c9ff4d33;
}

.custom-dropdown-toggle:hover {
  background-color: #bbff4b !important;
  color: black !important;
}

.avatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
}

.username {
  margin-left: 10px;
  cursor: pointer;
  color: white;
}

.addTeamBtn {
  margin-left: 20px;
  padding: 5px 10px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
}

.navMenu {
  position: absolute;
  bottom: 55px;
  right: auto;
  left: auto;
  width: 225px;
  background: #000;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.navMenu a {
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
}
.search-bar {
  display: flex;
  justify-content: flex-end;
  width: 25%;
  height: 100%;
  position: relative;
  top: 2rem;
}
.nav-tabs .nav-link.active {
  background-color: #202022 !important;
  color: #fff !important;
  font-family: Cont-regular !important;
}
tr {
  font-family: Cont-regular;
  font-size: 16px;
}
td {
  font-family: Cont-regular;
  font-size: 16px;
}
th {
  font-family: Cont-regular;
  font-size: 16px;
}
Button {
  font-family: Cont-regular;
}

.menu-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
}

.custom-dropdown {
  width: 195px;
  background-color: #333;
  /* border: 1px solid #ccc; */
  border-radius: 0.5rem;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 2.6rem;
  left: -170px;
  right: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.dropdown-item {
  padding: 0.75rem 1rem !important;
  cursor: pointer;
  color: #fff !important;
  /* background-color: rgb(69, 69, 80) !important; */
  font-family: Cont-regular;
  height: 50% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 1rem !important;
}

.dropdown-item:hover {
  background-color: #bbff4b !important;
  color: #000000 !important;
}

.dropdown-item:focus {
  background-color: #333;
  outline: none;
}

.dropdown-item.clicked {
  background-color: #bbff4b;
  color: #fff !important;
}
.dropdown-menu {
  background-color: #000 !important;
  border-radius: 1rem !important;
}

.ForgotPasswordNavigate:hover {
  cursor: pointer;
  color: #bbff4b;
}

.dropdown-toggle::after {
  vertical-align: middle !important;
}

input[type="date"] {
  display: block;
  -webkit-appearance: textfield;
  /* -moz-appearance: textfield; */
  /* min-height: 1.2em; */
  min-width: 75%;
}

#root
  > div.App
  > div
  > div.signupFormContainer
  > form
  > div.react-datepicker__tab-loop
  > div.react-datepicker-popper
  > div
  > div
  > div
  > div.react-datepicker__header.react-datepicker__header--custom
  > div:nth-child(1)
  > button {
    background: none ;
}

.card {
  background-color: #6f6f73;
  border-radius: 20px;
}
/* .dummy-bx{
      text-align: "left",
    display: "inline-block",
    width: "74px",
    height: "13px",
    backgroundColor: "rgb(187, 255, 75)",
    borderRadius: "4px",

} */
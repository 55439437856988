.signupFormMain {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #282c34;
  padding: 20px;
}
.LoginFormContainer {
  align-items: center;
  background: #333;
  display: flex;
  flex-direction: column;
  /* font-family: var(--text-font); */
  height: 100%;
  justify-content: center;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .signupFormMain {
    padding: 0;
    flex-direction: column;
    align-items: center;
  }
  .individualplanDetails {
    width: 100% !important;
    height: 60vh !important;
    margin-top: 1rem !important;
  }
  .signupFormContainer {
    width: 100% !important;
  }
  .signupFormContainer h1 {
    color: #ffff;
    font-size: 1.5rem !important;
    text-align: center;
    margin: 1rem !important;
  }
  .individualplanDetails h1 {
    color: #ffff;
    font-size: 1.5rem !important;
    text-align: center;
    margin: 1rem !important;
  }
}

.signupFormContainer {
  background: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 30%;
  font-family: var(--text-font);
}
.individualplanDetails {
  width: 30%;
  color: #fff;
  /* height: 60%; */
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
  justify-content: space-evenly;
  display: none;
}

.signupFormContainer h1 {
  color: #ffff;
  font-size: 1.5rem;
  width: 100% !important;
  text-align: center;
  padding: 1rem;
}
.signupFormContainer form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
}
.react-datepicker-wrapper {
  width: 100%;
}
.c2 {
  width: 100% !important;
  right: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 10px !important;
  border-radius: 5px !important;
  color: #202123;
  min-width: 95%;
  background-color: white !important;
}
.signupFormContainer input {
  width: 100%;
  padding: 12px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-family: Cont-regular;
}
select {
  width: 100%;
  padding: 12px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-family: Cont-regular;
  border: 2px solid black;
}
select:focus {
  outline: none;
  border-color: #bbff4b;
}
.signupFormContainer input:focus {
  outline: none;
  border-color: #bbff4b;
}

.signup-btn {
  width: 80%;
  border: none;
  border-radius: 5px;
  padding: 12px;
  margin-top: 5px;
  background-color: #bbff4b;
  color: #333;
  cursor: pointer;
  position: static;
}

.signup-btn:hover {
  background: #acff27;
}

.signupFormContainer span {
  font-size: 15px;
  color: red;
  margin-top: 10px;
}

#signupPassword {
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  justify-content: center;
}

#signupPassword svg {
  position: absolute;
  top: 10px;
  right: 60px !important;
  cursor: pointer;
}

.signupSeparator::before,
.signupSeparator::after {
  content: '\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0';
  text-decoration: line-through;
  padding: 0 15px;
  color: #fff;
}

.signupSeparator {
  font-size: 16px;
  color: #fff;
  padding: 20px 0;
}

#signupWithGoggle {
  background: #fff !important;
  color: #2d333a !important;
  border: 1px solid #202123 !important;
  font-size: 18px;
  display: flex;
  align-items: flex-start;
  column-gap: 20px;
}

#signupWithGoggle:hover {
  background-color: rgb(236, 236, 236) !important;
}

.login-button {
  background-color: #bbff4b;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin-top: 5px;
  padding: 12px;
  position: static;
  width: 100%;
}
